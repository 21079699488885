import React, { useState } from "react"
import { Modal, Button, Form } from "react-bootstrap";
import PinInput from "../../PinInput"
import { getUser, isLoggedIn } from "../../../services/auth"
import { updatePin } from "../../../services/account-services"

const SetupPinModal = ({ show }) => {
    const [notmatch, setNotmatch] = useState(false);
    const [pinUpdated, setPinUpdated] = useState(false);
    var showPin = false;
    if(!pinUpdated){
        showPin = show;
    }
    var pincode;
    var confirm_pincode;
    const onChange = value => {
        pincode = value;
    }
    const onConfirmChange = value => {
        confirm_pincode = value;
    }
    const onClose = value => {
    }
    const onPinSubmit = (event) => {
        event.preventDefault();
        if(pincode !== confirm_pincode){
            setNotmatch(true);
        }else{
            setNotmatch(false);
            const Member = getUser();
            var pinData = {
                Email: Member.Email,
                PinCode: pincode,
            };
            updatePin(pinData);
            setTimeout(function(){
                setPinUpdated(true);
            }, 700);            
        }
    };

  return (
    <Modal
      className="modalContainer"
      show={showPin}
      onHide={onClose}
      animation={true}
      size={'lg'}
    >
      <div className="modal-pin-inner">
        <h2>Set up a pin</h2>
        <p>Setup a 4 digit quick access pin to make logging in simple.</p>
        <p className={`text-danger${notmatch ? '' : ' d-none'}`}>Confirm Pin not match!</p>
        <div className="pinWrap">
          <PinInput
            length={4}
            focus
            secret
            type="numeric"
            label={'Enter Pin*'}
            onChange={onChange}
          />
        </div>
        <div className="pinWrap">
          <PinInput
            length={4}
            focus
            secret
            type="numeric"
            label={'Confirm Pin*'}
            onChange={onConfirmChange}
          />
        </div>
        <div className="mt-4 d-flex justify-content-center">
          <button className="btn btn-black btn-lg btn-w210" onClick={onPinSubmit}>
            FINISH
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SetupPinModal
