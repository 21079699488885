import React, { useState } from "react"
import { Modal, Button, Form } from "react-bootstrap";
import classNames from "classnames"
import { setUser, getUser, isLoggedIn } from "../../../services/auth"
import { updateUserPreferences } from "../../../services/account-services"
import SetupPinModal from "./SetupPinModal"

const PreferencesModal = ({ show }) => {
    var showPreferences = false;
    const [showSetupPin, setShowSetupPin] = useState(false);
    const Member = getUser();
    if(Member && !showSetupPin){
        showPreferences = show;
    }
    const handleClosePreferences = () => {
        showPreferences = false;
    }
    const handleShowPreferences = () => {
        showPreferences = true;
    }    
    
    const handleCloseSetupPin = () => setShowSetupPin(false);
    const handleShowSetupPin = () => setShowSetupPin(true);    
    
    const onPreferencesFormSubmit = (event) => {
        event.preventDefault();
        
        var preferences = {
            Email: Member.Email,
            KnockdownRebuild: event.target.elements.KnockdownRebuild.checked ? 1 : 0,
            SingleStorey: event.target.elements.SingleStorey.checked ? 1 : 0,
            PoolHouse: event.target.elements.PoolHouse.checked ? 1 : 0,
            DoubleStorey: event.target.elements.DoubleStorey.checked ? 1 : 0,
            IHaveKids: event.target.elements.IHaveKids.checked ? 1 : 0,
            HomeOffice: event.target.elements.HomeOffice.checked ? 1 : 0,
            MediaRoom: event.target.elements.MediaRoom.checked ? 1 : 0,
            IHavePets: event.target.elements.IHavePets.checked ? 1 : 0,
            IHaveLand: event.target.elements.IHaveLand.checked ? 1 : 0,
            GrannyFlat: event.target.elements.GrannyFlat.checked ? 1 : 0,
            ContactByEmail: event.target.elements.ContactByEmail.checked ? 1 : 0,
            ContactByPhone: event.target.elements.ContactByPhone.checked ? 1 : 0,
        };
        updateUserPreferences(preferences);
        showPreferences = false;
        setTimeout(function(){
            let user = getUser();
            let valid_user = Object.keys(user).length > 0;
            if(valid_user){
                handleShowSetupPin();
            }
        },700);
  };
  return (
    <>
        <Modal
          className="modalContainer"
          show={showPreferences}
          onHide={handleClosePreferences}
          animation={true}
          size={'lg'}
        >
          <div className="modal-content-inner">
              <div className="account-form">
                    <ReferencesForm onSubmit={event => {onPreferencesFormSubmit(event)}} />
              </div>
            </div>
        </Modal>
        <SetupPinModal show={showSetupPin} />
    </>
  )
}

const ReferencesForm = ({ onSubmit }) => {
    const Member = getUser();
    return(
        <>
            <Form method="post" onSubmit={onSubmit}>
                <h2 className="text-center">Thanks<br></br>{Member.FirstName}!</h2>
                    <p className="pref-text">CHOOSE YOUR HOME PREFERENCES</p>
                    <div className="d-flex flex-wrap">
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.KnockdownRebuild} name="KnockdownRebuild" value="1" type="checkbox" className="custom-control-input" id="KnockdownRebuild"></input>
                            <label className="custom-control-label" htmlFor="KnockdownRebuild">Knockdown Rebuild</label>
                        </div>
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.SingleStorey} name="SingleStorey" value="1" type="checkbox" className="custom-control-input" id="SingleStorey"></input>
                            <label className="custom-control-label" htmlFor="SingleStorey">Single Storey</label>
                        </div>
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.PoolHouse} name="PoolHouse" value="1" type="checkbox" className="custom-control-input" id="PoolHouse"></input>
                            <label className="custom-control-label" htmlFor="PoolHouse">Pool House</label>
                        </div>
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.DoubleStorey} name="DoubleStorey" value="1" type="checkbox" className="custom-control-input" id="DoubleStorey"></input>
                            <label className="custom-control-label" htmlFor="DoubleStorey">Double Storey</label>
                        </div>
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.IHaveKids} name="IHaveKids" value="1" type="checkbox" className="custom-control-input" id="IHaveKids"></input>
                            <label className="custom-control-label" htmlFor="IHaveKids">I have kids</label>
                        </div>
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.HomeOffice} name="HomeOffice" value="1" type="checkbox" className="custom-control-input" id="HomeOffice"></input>
                            <label className="custom-control-label" htmlFor="HomeOffice">Home Office</label>
                        </div>
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.MediaRoom} name="MediaRoom" value="1" type="checkbox" className="custom-control-input" id="MediaRoom"></input>
                            <label className="custom-control-label" htmlFor="MediaRoom">Media Room</label>
                        </div>
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.ContactByPhone} name="IHavePets" value="1" type="checkbox" className="custom-control-input" id="IHavePets"></input>
                            <label className="custom-control-label" htmlFor="IHavePets">I have pets</label>
                        </div>
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.IHaveLand} name="IHaveLand" value="1" type="checkbox" className="custom-control-input" id="IHaveLand"></input>
                            <label className="custom-control-label" htmlFor="IHaveLand">I have land</label>
                        </div>
                        <div className="custom-control custom-checkbox w-100 w-sm-50 mb-3">
                            <input defaultChecked={Member.GrannyFlat} name="GrannyFlat" value="1" type="checkbox" className="custom-control-input" id="GrannyFlat"></input>
                            <label className="custom-control-label" htmlFor="GrannyFlat">Granny Flat</label>
                        </div>
                    </div>
                    <p className="pref-text">CHOOSE YOUR COMMUNICATION PREFERENCES</p>
                    <div className="pref-check-wrap">
                      <div className="custom-control custom-checkbox revert">
                        <input defaultChecked={Member.ContactByEmail} name="ContactByEmail" value="1" type="checkbox" className="custom-control-input" id="ContactByEmail"></input>
                        <label className="custom-control-label" htmlFor="ContactByEmail">Email</label>
                      </div>
                      <div className="custom-control custom-checkbox revert">
                        <input defaultChecked={Member.ContactByPhone} name="ContactByPhone" value="1" type="checkbox" className="custom-control-input" id="ContactByPhone"></input>
                        <label className="custom-control-label" htmlFor="ContactByPhone">Phone or SMS</label>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center button-wrap">
                      <button type="submit" className="btn btn-black btn-lg btn-w210">
                        GET STARTED
                      </button>
                    </div>
            </Form>
        </>
    )
}
export default PreferencesModal
